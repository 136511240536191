// primary //

$legacy-blue: #005595;
$legacy-blue-dark: #153F6B;
$legacy-blue-light: #4684B3;
$legacy-blue-extra-light: #A2C1D8;

$legacy-green: #63883D;
$legacy-green-dark: #44632E;
$legacy-green-light: #88A862;
$legacy-green-extra-light: #C5D3B0;

$discovery-gold: #B4975A;

// primary variations //

$legacy-blue-bright: #3388CA;
$legacy-blue-bright-background: #E8F0F3;
$legacy-green-bright: #94C35B;

// complimentary //

$complimentary-orange: #E68524;
$complimentary-orange-light: #F3BA6D;
$complimentary-orange-background: #FAE8D4;

// secondary //

$secondary-red: #CD2617;
$secondary-red-light: #F3946D;
$secondary-red-background: #FAD9D2;

$secondary-yellow: #E8AE34;
$secondary-yellow-light: #F3D26D;
$secondary-yellow-background: #FEF3D1;

// purple //
$dark-purple: #572A62;
$dark-purple-background: #734E7C;

// grays //
$gray-extra-dark: #222;
$gray-dark: #333;
$gray-light: #A9A9A9;
$gray-medium: #7A7A7A;
$gray-extra-light: #F0F0F0;

$defaultButtonBackground: #7A7A7A;

// contextual color definitions //

$color-success: $legacy-green;
$color-success-background: #E0E9D8;

$color-primary: $legacy-blue;
$color-primary-background: #E8F0F3;

$legacy-purple: $dark-purple;
$legacy-purple-background: $dark-purple-background;

$legacy-baby-blue: #3A8AC5;

$color-warning: $complimentary-orange;
$color-warning-background: $complimentary-orange-background;

$color-info: $legacy-blue-bright;
$color-info-background: $legacy-blue-bright-background;

$color-danger: $secondary-red;
$color-danger-background: $secondary-red-background;

$color-error: $color-danger;
$color-error-background: $color-danger-background;

$color-inverse: $gray-extra-dark;

$color-facebook-background: #293F74;

// Toolkit colors
$sbf-green: #63883D;
$sbf-light-green: #93C45B;
$sbf-dark-green: #1C6B3D;
$sbf-mint-green: #11A05B;
$sbf-blue: #005595;
$sbf-light-blue: #3588C8;
$sbf-gold: #B4975A;
$sbf-orange: #E58424;
$sbf-yellow: #F3D36E;
$sbf-dark-yellow: #E9AF1F;
$sbf-black: #353535;
$sbf-white: #F5F5F5;
$sbf-dark-pink: #81254A;
$sbf-red: #CD2617;
$sbf-purple: #562363;
$sbf-brown: #943F2B;
$sbf-peach: #F4966E;
$sbf-pink: #C07493;
$sbf-tan: #CA8A7C;
$sbf-light-orange: #F4BA6C;
$sbf-light-purple: #A66EAE;
$sbf-edit-green: #11A05B;

@mixin success-light-on-dark() {
  background-color: $color-success-background;
  color: $color-success;
}

@mixin success-white-text() {
  background-color: $color-success;
  color: white;
}

@mixin primary-light-on-dark() {
  background-color: $color-primary-background;
  color: $color-primary;
}

@mixin primary-white-text() {
  background-color: $color-primary;
  color: white;
}

@mixin warning-light-on-dark() {
  background-color: $color-warning-background;
  color: $color-warning;
}

@mixin purple-white-text() {
  background-color: $legacy-purple;
  color: white;
}

@mixin baby-blue-white-text() {
  background-color: $legacy-baby-blue;
  color: white;
}

@mixin blue-white-text() {
  background-color: $legacy-blue;
  color: white;
}

@mixin warning-white-text() {
  background-color: $color-warning;
  color: white;
}

@mixin info-light-on-dark() {
  background-color: $color-info-background;
  color: $color-info;
}

@mixin info-white-text() {
  background-color: $color-info;
  color: white;
}

@mixin error-light-on-dark() {
  background-color: $color-danger-background;
  color: $color-danger;
}

@mixin danger-light-on-dark() {
  @include error-light-on-dark;
}

@mixin error-white-text() {
  background-color: $color-danger;
  color: white;
}

@mixin danger-white-text() {
  @include error-white-text;
}

@mixin inverse-white-text() {
  background-color: $color-inverse;
  color: white;
}

@mixin neutral-light-on-dark() {
  color: $gray-dark;
  background-color: $gray-extra-light;
}

@mixin facebook-blue() {
  background-color: $color-facebook-background;
  color: white;

  &:hover {
    background-color: lighten($color-facebook-background, 6%);
  }
}

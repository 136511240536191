/* the new new clearfix */

/* http://www.css-101.org/articles/clearfix/latest-new-clearfix-so-far.php */
.cf::after,
.self-clear::after,
.cf-as-mixin {
  content: "";
  display: table;
  clear: both;
}

//wrap youtube, vimeo, etc videos in a div with the .responsive-video class for magic
.responsive-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  margin: 20px 0;
  -moz-box-shadow: 0 0 5px #555;
  -webkit-box-shadow: 0 0 5px #555;
  box-shadow: 0 0 5px #555;
}

.responsive-video iframe,
.responsive-video object,
.responsive-video embed,
.responsive-video div.sbf-ce-section {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

//wrap picures with the shadow corners in a div with the .picture-shadow class for magic
//override the max-width on these if necessary, but have a good reason -- may cause quality issues
.picture-shadow {
  width: 100%;
  max-width: 290px;
  margin: 0 auto;
}

.picture-shadow img {
  width: 100%;
  max-width: 290px;
  margin: 0 auto;
  display: block;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}

.picture-shadow::before,
.picture-shadow::after {
  max-width: 290px;
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 10%;
  -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.3);
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.picture-shadow::after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  transform: rotate(3deg);
}

@mixin scale($scale: 1) {
  -webkit-transform: scale($scale);
  -moz-transform: scale($scale);
  -o-transform: scale($scale);
  -ms-transform: scale($scale);
  transform: scale($scale);
}

.tablet-only {
  display: none;

  @media (min-width: 760px) { display: inline; } // (min-width: 768px)

  @media (min-width: 900px) { display: none; }
}

@import '../../css_variables/breakpoints';
@import '../../css_variables/typography';

@media (min-width: $medium-minwidth) {
  div.info {
    span.name {
      @include block-text-23($mediumBasePixels);
    }
  }

  .sbf-datepicker + select {
    margin-left: 16px;
    margin-left: 1rem;
  }

  .sbf-datepicker + select + select {
    margin-left: 16px;
    margin-left: 1rem;
  }

  .sbf-datepicker + select + select + select {
    margin-left: 16px;
    margin-left: 1rem;
  }
}

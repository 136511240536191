@import '../../css_variables/breakpoints';
@import '../../css_variables/typography';

@media (min-width: $zero-minwidth) {
  div.info {
    span.name {
      @include block-text-23($smallBasePixels);
    }
  }

  input[type="button"],
  input[type="submit"],
  input[type="reset"],
  button,
  .button {
    filter: none; //for IE9 so that gradients dont bleed outside of rounded corners
  }

  .sbf-datepicker + select {
    margin-left: 14px;
    margin-left: 0.585rem;
  }

  .sbf-datepicker + select + select {
    margin-left: 14px;
    margin-left: 0.585rem;
  }

  .sbf-datepicker + select + select + select {
    margin-left: 14px;
    margin-left: 0.585rem;
  }
}

@font-face {
  // This font tweaks any unicode characters that we're unhappy with (currently just ℠)
  font-family: 'unicode-tweaks';
  src:
    url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMgf9J30AAAC8AAAAYGNtYXDfNEKXAAABHAAAAExnYXNwAAAAEAAAAWgAAAAIZ2x5Zg3ELtsAAAFwAAABbGhlYWQANObRAAAC3AAAADZoaGVhA4kBugAAAxQAAAAkaG10eAKAAC8AAAM4AAAAFGxvY2EAKADKAAADTAAAAAxtYXhwAAgAdwAAA1gAAAAgbmFtZUQYtNYAAAN4AAABOXBvc3QAAwAAAAAEtAAAACAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABAAAACAAAAAAAAAAAAAAAAABAAAAhIAGz/7T/tAGzAEwAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAICEg//3//wAAAAAAICEg//3//wAB/+Pe5AADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAAAAAAAAAIAADc5AQAAAAABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAgAvAJQB1AFsAFoAdAAANzQ+AjceAxUUDgIHHgMXPgM1NC4CJy4DNTQ+Ahc2HgIVFA4CBy4DNTQ+AjEuAiInDgMVFB4CFx4DFRQOAiMiLgI1FzM3IzUzFzczFSMXMxUjNTMnByMnBzMVIzUvAwUGAwQGBAMBAQIBAgkKDQcLEQsGBgwQCQ4XEQoKEBgPDRcRCQIFBgMEBgUCAQECAgcJCwUJDwoGBwwRCg0WEQkKExkPDxoSC6gbCBwzRUQzHAgbTRoFRAZEBRtNvwUHBgIBAQIFBQQBBQIEAQMEBQEBAQQLDQkHDQkIAwYKDxIPCxUOCQEBBwsQBwUGBgEBAQEGBQQBBAIEAwMEAQEDCgsICA0JCAMGCg8SDg0XDgkHDQ8IE6gVj48VqBUVk4qKkxUVAAEAAAABAAC/N6lQXw889QALAgAAAAAAz6FTNAAAAADPoVM0AAAAAAHUAWwAAAAIAAIAAAAAAAAAAQAAAbP/tAAAAgAAAAAAAdQAAQAAAAAAAAAAAAAAAAAAAAUAAAAAAAAAAAAAAAAAgAAAAgAALwAAAAAACgAUAB4AtgABAAAABQB1AAIAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADgCuAAEAAAAAAAEADgAAAAEAAAAAAAIADgBHAAEAAAAAAAMADgAkAAEAAAAAAAQADgBVAAEAAAAAAAUAFgAOAAEAAAAAAAYABwAyAAEAAAAAAAoAKABjAAMAAQQJAAEADgAAAAMAAQQJAAIADgBHAAMAAQQJAAMADgAkAAMAAQQJAAQADgBVAAMAAQQJAAUAFgAOAAMAAQQJAAYADgA5AAMAAQQJAAoAKABjAGkAYwBvAG0AbwBvAG4AVgBlAHIAcwBpAG8AbgAgADEALgAwAGkAYwBvAG0AbwBvAG5pY29tb29uAGkAYwBvAG0AbwBvAG4AUgBlAGcAdQBsAGEAcgBpAGMAbwBtAG8AbwBuAEcAZQBuAGUAcgBhAHQAZQBkACAAYgB5ACAASQBjAG8ATQBvAG8AbgAAAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype'),
                 url(data:application/font-woff;charset=utf-8;base64,d09GRk9UVE8AAATQAAoAAAAABIgAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAABDRkYgAAAA9AAAAVIAAAFSmazbwE9TLzIAAAJIAAAAYAAAAGAH/Sd9Y21hcAAAAqgAAABMAAAATN80QpdnYXNwAAAC9AAAAAgAAAAIAAAAEGhlYWQAAAL8AAAANgAAADYANObRaGhlYQAAAzQAAAAkAAAAJAOJAbpobXR4AAADWAAAABQAAAAUAoAAL21heHAAAANsAAAABgAAAAYABVAAbmFtZQAAA3QAAAE5AAABOUQYtNZwb3N0AAAEsAAAACAAAAAgAAMAAAEABAQAAQEBCGljb21vb24AAQIAAQA/+BwC+BsD+BgEHgoAGVP/i4seCgAZU/+LiwwHix7nao/4lB5DWi8FHQAAAHsPHQAAAIARHQAAAAkdAAABSRIABgEBCA8RExYbaWNvbW9vbmljb21vb251MHUxdTIwdTIxMjAAAAIBiQADAAUBAQQHCg3B/JQO/JQO/JQO/BQOuvdTFYuXk5OUi5WLkoSLgouGiYaIh5KEmoWei6iLm5qLoYuheJRzlWeYb5mLr4urpaKyiwiti6d4i3WLf4SFgouBi4SSi5SLkI2PjY6GkXuPfotyi3x+i3iLdJ+CpYGufqV9i2gIi2ZudWOLYotuoYugCPc8eBWmi5P3PG+Li6C+i9D7I8/3I76Li3Zvi5P7PKaLi3Y+i4ugpYuG9ydH+x6Fi0f3Hob7J6aLi3Y+iwWLoAUO+JQU+JQViwwKAAAAAwIAAZAABQAAAUwBZgAAAEcBTAFmAAAA9QAZAIQAAAAAAAAAAAAAAAAAAAABAAAACAAAAAAAAAAAAAAAAABAAAAhIAGz/7T/tAGzAEwAAAABAAAAAAAAAAAAAAAgAAAAAAACAAAAAwAAABQAAwABAAAAFAAEADgAAAAKAAgAAgACAAEAICEg//3//wAAAAAAICEg//3//wAB/+Pe5AADAAEAAAAAAAAAAAAAAAEAAf//AA8AAQAAAAEAAOj+9jhfDzz1AAsCAAAAAADPoVM0AAAAAM+hUzQAAAAAAdQBbAAAAAgAAgAAAAAAAAABAAABs/+0AAACAAAAAAAB1AABAAAAAAAAAAAAAAAAAAAABQAAAAAAAAAAAAAAAACAAAACAAAvAABQAAAFAAAAAAAOAK4AAQAAAAAAAQAOAAAAAQAAAAAAAgAOAEcAAQAAAAAAAwAOACQAAQAAAAAABAAOAFUAAQAAAAAABQAWAA4AAQAAAAAABgAHADIAAQAAAAAACgAoAGMAAwABBAkAAQAOAAAAAwABBAkAAgAOAEcAAwABBAkAAwAOACQAAwABBAkABAAOAFUAAwABBAkABQAWAA4AAwABBAkABgAOADkAAwABBAkACgAoAGMAaQBjAG8AbQBvAG8AbgBWAGUAcgBzAGkAbwBuACAAMQAuADAAaQBjAG8AbQBvAG8Abmljb21vb24AaQBjAG8AbQBvAG8AbgBSAGUAZwB1AGwAYQByAGkAYwBvAG0AbwBvAG4ARwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAAAAAAMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA=) format('woff');
  font-weight: normal;
  font-style: normal;
  unicode-range: U+2120;
}

@import '../../css_variables/color';
@import '../../css_variables/typography';

html,
body {
  font-family: $font-family-sans;
  font-size: 16px;
  line-height: 23px;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: $gray-dark;
}

h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dd,
header,
p,
small,
figure,
address,
blockquote,
label,
pre,
table,
fieldset,
hr {
  margin-bottom: 23px; // this number MUST match the line-height set on the body
}

::selection {
  color: #FFF;
  background: $legacy-green-bright;
}

h1 {
  font-weight: 700;

  @include block-text-60($largeBasePixels);
}

h2 {
  font-weight: 700;

  @include block-text-37($largeBasePixels);
}

h3 {
  @include block-text-26($largeBasePixels);

  line-height: 1.2;
}

h4 {
  @include block-text-23($largeBasePixels);
}

h5 {
  @include block-text-20($largeBasePixels);
}

h6 {
  @include block-text-16($largeBasePixels);
}

h1.heavy {
  text-align: center;
}

h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  display: inline;
  color: #999;
  font-weight: normal;
  margin-bottom: 0;
  font-size: 0.618em;
}

h1.serif,
h2.serif,
h3.serif,
h4.serif,
h5.serif,
h6.serif {
  font-family: $font-family-serif;
  font-weight: 700;
}

h2.golden,
.golden_h2_mixin {
  font-family: $font-family-serif;
  font-weight: 600;
  text-transform: uppercase;

  @include block-text-16($largeBasePixels);

  letter-spacing: 2px;
  color: #B4975A;
  margin-bottom: 0;
  margin-top: 37px;
}

h2.underlined {
  border-bottom: 1px solid #DBDBDB;
  padding-bottom: 10px;
  padding-bottom: 0.75rem;
  margin-bottom: 16px;
  margin-bottom: 1rem;
}

a {
  color: $legacy-blue;
  text-decoration: none;
}

a.underline {
  border-bottom: 1px solid $legacy-blue;
}

a:hover {
  color: desaturate(lighten($legacy-blue, 14%), 20%);
}

a.underline:hover {
  border-bottom: 1px solid desaturate(lighten($legacy-blue, 14%), 20%);
}

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: none;
}

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */

a:hover,
a:active {
  outline: 0;
}

address {
  font-style: normal;
}

img {
  border: 0;
  max-width: 100%;
}

img ~ .caption {
  @include block-text-14($largeBasePixels);

  color: #707070;
  font-family: $font-family-sans;
  font-weight: 500;
  margin-bottom: 23px;
  margin-bottom: 1.618rem;
}

ul.nav {
  margin-bottom: 0;
  margin-left: 0;
}

ul.nav li {
  display: inline-block;
}

code,
pre {
  border-radius: 3px;
  -moz-border-radius: 3px;
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 14px;
  font-size: 0.885rem;
}

code {
  border: 1px solid #E1E1E8;
  background-color: #F7F7F9;
  background-color: rgba(246, 246, 249, 0.75);
  border-color: rgba(0, 0, 0, 0.1);
  padding: 2px 4px;
  color: #194;
  letter-spacing: normal !important;
}

pre,
pre.prettyprint {
  display: block;
  padding: 10px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #F5F5F5;
  border: 1px solid #CCC;
  border-color: rgba(0, 0, 0, 0.15);
}

p,
ul,
ol,
dl {
  font-family: $font-family-serif;

  @include block-text-16($largeBasePixels);
}

p.lead {
  @include block-text-20($largeBasePixels);
}

p.sans,
ul.sans,
ol.sans,
dl.sans {
  font-family: $font-family-sans;
}

div.serif,
span.serif,
small.serif,
em.serif,
strong.serif {
  font-family: $font-family-serif;
}

.note {
  color: #777;
}

ul,
ol {
  padding: 0;
  margin: 0 0 9px 25px;
}

ul ul,
ul ol,
ol ol,
ol ul {
  margin-bottom: 0;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

ul.inline,
ol.inline {
  margin-left: 0;
}

ul.inline li,
ol.inline li {
  display: inline-block;
  list-style: none;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 9px;
}

hr {
  margin: 23px 0 42px;
  border: 0;
  background: url('hr.png') center no-repeat;
  height: 23px;

  &.inverse {
    background-image: url('hr_inverse.png');
  }

  &.dark {
    background-image: url('hr_dark.png');
  }

  &.gray {
    background-image: url('hr-gray.png');
  }

  &.green {
    background-image: url('hr-green.png');

    &.dark {
      background-image: url('hr-green.png');
    }
  }

  &.purple {
    background-image: url('hr-purple.png');
  }

  &.nomark {
    width: auto;
    height: 1px;
    background: rgb(255, 255, 255); /* Old browsers */
  }

  &.separator {
    width: auto;
    margin: 0 0 16px 0;
    margin-bottom: 1rem;
    height: 2px;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-top-color: rgba(0, 0, 0, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background: none;
  }
}

strong {
  font-weight: 600;
}

em {
  font-style: italic;
}

abbr {
  letter-spacing: 0.1em;
  font-variant: small-caps;
  font-size: 0.95em;
}

abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #999;
}

blockquote {
  padding: 0 0 0 15px;
  margin: 0 0 18px;
  border-left: 5px solid #EEE;
}

blockquote p {
  margin-bottom: 0;

  @include block-text-23($largeBasePixels);

  font-family: $font-family-sans;
  font-weight: 300;
}

blockquote small {
  display: block;
  color: #999;

  @include block-text-16($largeBasePixels);
}

blockquote small::before {
  content: '\2014 \00A0';
}

table {
  width: 100%;
  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
}

table th,
table td {
  padding: 8px;
  text-align: left;
  vertical-align: top;
  border-top: 1px solid #DDD;
}

table th {
  font-weight: 700;
}

table thead th {
  vertical-align: bottom;
}

table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
  border-top: 0;
}

table tbody + tbody {
  border-top: 2px solid #DDD;
}

table.striped tbody tr:nth-child(odd) td,
table.striped tbody tr:nth-child(odd) th {
  background-color: #F9F9F9;
}

table tbody tr:hover td,
table.striped tbody tr:hover td,
table tbody tr:hover th,
table.striped tbody tr:hover th {
  background-color: #F5F5F5;
}

table.bordered {
  border: 1px solid #DDD;
  border-collapse: separate;
  border-radius: 4px;
  -moz-border-radius: 4px;
  border-left: 0;
}

table.bordered th,
table.bordered td {
  border-left: 1px solid #DDD;
}

table.bordered caption + thead tr:first-child th,
table.bordered caption + tbody tr:first-child th,
table.bordered caption + tbody tr:first-child td,
table.bordered colgroup + thead tr:first-child th,
table.bordered colgroup + tbody tr:first-child th,
table.bordered colgroup + tbody tr:first-child td,
table.bordered thead:first-child tr:first-child th,
table.bordered tbody:first-child tr:first-child th,
table.bordered tbody:first-child tr:first-child td {
  border-top: 0;
}

table.bordered thead:first-child tr:first-child th:first-child,
table.bordered tbody:first-child tr:first-child td:first-child {
  border-top-left-radius: 4px;
}

table.bordered thead:first-child tr:first-child th:last-child,
table.bordered tbody:first-child tr:first-child td:last-child {
  border-top-right-radius: 4px;
}

table.bordered thead:last-child tr:last-child th:first-child,
table.bordered tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 4px;
  -moz-border-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-bottom-left-radius: 4px;
}

table.bordered thead:last-child tr:last-child th:last-child,
table.bordered tbody:last-child tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
  -moz-border-bottom-right-radius: 3px;
}

table col[class*="span"] {
  float: none;
  margin-left: 0;
}

fieldset {
  border: none;
}

label {
  display: block;
}

input,
textarea,
select,
button {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $gray-light;
}

::-webkit-input-placeholder {
  color: $gray-light;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $gray-light;
}

.placeholder_polyfill_applied {
  color: $gray-light;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

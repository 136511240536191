$sourceSans: 'Source Sans 3', 'Helvetica Neue', Arial, Helvetica, sans-serif;
$zillaSlab: 'Zilla Slab', Georgia, serif;

$font-family-sans: $sourceSans;
$font-family-serif: $zillaSlab;

$font-family-icons: 'SSSymbolicons';

@mixin include-font($family, $file, $weight, $e) {
  font-family: $family;
  src: url('${file}.eot');
  src: url('${file}.eot?#iefix') format('embedded-opentype'), url('${file}.woff') format('woff'), url('${file}.ttf') format('truetype');
  font-weight: $weight;
  font-style: $e;
}

$largeBasePixels: 16;
$mediumBasePixels: 16;
$smallBasePixels: 14;

@mixin fontSizePxRems($fontSizeInPixels, $basePixels) {
  font-size: $fontSizeInPixels + px;
  $remSize: calc($fontSizeInPixels / $basePixels);

  font-size: $remSize + rem;
}

@mixin lineHeightPxRems($lineHeightInPixels, $basePixels) {
  line-height: $lineHeightInPixels + px;
  $remSize: calc($lineHeightInPixels / $basePixels);

  line-height: $remSize + rem;
}

@mixin block-text ($fontSizeInPixels, $lineHeightInPixels, $basePixels) {
  @if $fontSizeInPixels == 60 or $fontSizeInPixels == 42 or $fontSizeInPixels == 37 or
    $fontSizeInPixels == 26 or $fontSizeInPixels == 23 or $fontSizeInPixels == 20 or
    $fontSizeInPixels == 16 or $fontSizeInPixels == 14 or $fontSizeInPixels == 10 {
    @include fontSizePxRems($fontSizeInPixels, $basePixels);
  }

  @if $lineHeightInPixels == 68 or $lineHeightInPixels == 60 or $lineHeightInPixels == 42 or
    $lineHeightInPixels == 37 or $lineHeightInPixels == 26 or $lineHeightInPixels == 23 or
    $lineHeightInPixels == 20 or $lineHeightInPixels == 16 or $lineHeightInPixels == 14 {
    @include lineHeightPxRems($lineHeightInPixels, $basePixels);
  }
}

@mixin block-text-60 ($basePixels: 14) {
  @include block-text(42, 60, $basePixels);
}

@mixin block-text-60($basePixels: 14) {
  @include block-text(60, 68, $basePixels);
}

@mixin block-text-42($basePixels: 14) {
  @include block-text(42, 60, $basePixels);
}

@mixin block-text-37($basePixels: 14) {
  @include block-text(37, 42, $basePixels);
}

@mixin block-text-26($basePixels: 14) {
  @include block-text(26, 37, $basePixels);
}

@mixin block-text-23($basePixels: 14) {
  @include block-text(23, 26, $basePixels);
}

@mixin block-text-20($basePixels: 14) {
  @include block-text(20, 26, $basePixels);
}

@mixin block-text-16($basePixels: 14) {
  @include block-text(16, 23, $basePixels);
}

@mixin block-text-14($basePixels: 14) {
  @include block-text(14, 20, $basePixels);
}

@mixin block-text-10($basePixels: 14) {
  @include block-text(10, 16, $basePixels);
}

@import '../../css_variables/breakpoints';
@import '../../css_variables/typography';

@media (min-width: $large-minwidth) {
  div.info {
    span.name {
      @include block-text-23($largeBasePixels);
    }
  }
}

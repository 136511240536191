@import '../../css_variables/breakpoints';

@media (min-width: $large-minwidth) {
  html,
  body {
    font-size: 16px;
    line-height: 23px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dd,
  header,
  p,
  small,
  figure,
  address,
  blockquote,
  label,
  pre,
  table,
  fieldset,
  hr {
    margin-bottom: 23px; // this number MUST match the line-height set on the body
  }
}

// Requires
//  php-legacy/typography.less
//  php-legacy/colors.less
//
// the new image text replacement method //
@mixin hide-text() {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@mixin rounded-corners($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin all-caps-heading() {
  color: $discovery-gold;
  font-size: 0.885rem;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  font-family: $font-family-serif;
}

$grid-large-minwidth: 980px;
$grid-large-breakpoint: 979px;

$grid-medium-minwidth: 680px;
$grid-medium-breakpoint: 679px;

$zero-minwidth: 0;
$tiny-minwidth: 320px;
$xs-minwidth: 480px;
$small-minwidth: 600px;
$medium-minwidth: 768px;
$large-minwidth: 960px;
$xl-minwidth: 1040px;

$xs-maxwidth: 479px;
$small-maxwidth: 599px;
$medium-maxwidth: 767px;
$large-maxwidth: 959px;

@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/util';
@import 'util';

/*
* This is the medium (tablet) version
*/
@media (min-width: 760px) {
  div.inner {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  #header {
    height: 142px;
    border-bottom: #B4975A 3px solid;

    &.simple {
      height: 92px;
      text-align: left;

      a.home {
        margin-top: 0;
      }
    }

    &.login div.inner {
      position: relative;
    }

    .logo {
      @include scale(0.8);

      margin-top: 33px;
    }

    &.login .logo {
      margin-top: 26px;
    }

    &.search {
      height: 143px;
    }

    &.search div.inner div {
      h1 {
        @include block-text-26($mediumBasePixels);

        margin-top: -4px;
      }

      .need-help {
        @include block-text-16($mediumBasePixels);
      }

      .small-hidden {
        display: inline;
      }

      a.home {
        top: inherit;
      }
    }

    a.home {
      top: 0;
      margin: 0;
    }

    form.search {
      margin-left: 0;
      width: 258px;

      @include rounded-corners(4px);

      background-color: #3D581D;
      padding: 4px;

      label {
        margin: 0;
        padding: 1px 4px;
      }

      input {
        box-shadow: none;
        border: 0;
        background-color: #FFF;
        color: #444;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;

        @include rounded-corners(2px);

        height: 35px;
      }

      div {
        background-color: #FFF;
      }

      button {
        top: 28%;
        right: 12px;
      }
    }

    a.menu {
      display: none;
    }
  }

  #header a.donate {
    top: 40px;
    margin-right: 20px;
    position: absolute;
    right: 271px;
  }

  #header .shortcuts {
    background-color: transparent;
    border: none;
    top: 40px;
    padding-right: 20px;
    overflow: hidden;
    height: auto;
    position: absolute;
    left: auto;
    bottom: auto;
    right: 0;
  }

  #menu {
    position: absolute;
    background-color: transparent;
    top: 4px;
    right: -5px;
    margin-right: 10px;
    font-size: 12px;
    text-align: right;
    color: #FFF;

    a {
      color: inherit;

      &:hover {
        color: #FFF;
      }
    }

    a.contact {
      display: inline;
    }

    a.members {
      display: inline;
    }

    a.contact::before {
      content: ' \00b7\ '; /* middot */
    }

    a.members::before {
      content: ' \00b7\ '; /* middot */
    }

    .secondrow {
      display: inline;
    }

    div.inner {
      padding-right: 20px;
    }

    a.top {
      display: none;
    }

    a.emails {
      display: none;
    }
  }

  #header.simple ~ #menu .inner {
    padding-right: 20px;
  }

  /////////////////////////////

  .primary-nav {
    background-color: transparent;
    position: absolute;
    top: 94px;
    left: 0;
    right: 0;
    font-family: $font-family-serif;
    text-align: center;
    line-height: 1;
    font-weight: 500;
    border-top: $legacy-green-dark 1px solid;
    box-shadow: inset rgba(255, 255, 255, 0.1) 0 1px 0 0;

    a {
      font-family: $font-family-serif;
      color: white;
      display: inline-block;
      border-bottom: none;
      padding: 11px 16px 15px;
    }

    li {
      background: transparent;
      border-top: none;
      font-size: 18px;
    }

    li::before {
      content: ' \00b7\ ';
      font-weight: bold;
    }

    li.current ul {
      display: none;
    }

    li:first-child::before {
      content: '';
    }

    .nav > li:hover > a,
    .nav > li.current > a {
      &::after {
        display: none;
      }
    }

    ul ul {
      padding: 0;
    }

    ul ul li:hover a {
      background-image: none;
    }

    ul ul li::before {
      content: '';
    }

    li ul {
      display: none;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 177px;
      background-color: #E8F0F5; /* pinned */
      background-color: #B4975A; /* drop down */
      text-align: left;
      overflow: hidden;
      font-size: 13px;
      line-height: 18px;
      border-radius: 0 0 5px 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      z-index: 1; /* needs a boost about primary-nav and anything else */
      font-family: $font-family-serif;
      top: 45px;
    }

    li:hover ul {
      display: block;
    }

    li:hover a {
      color: white;
    }

    li ul li {
      display: block;
      font-size: 16px;
    }

    li ul a {
      display: block;
      width: auto;
      padding: 10px 20px;
    }

    li ul a:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .primary-nav > .inner > nav > ul > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }

  .primary-nav ul li {
    display: inline-block;
  }

  //////////////////////////

  #footer {
    padding-top: 37px;
    padding-bottom: 36px;
  }

  #footer #find {
    margin-right: 0;
  }

  #footer .nav {
    margin: 0;
  }

  #footer a.home {
    width: 62px;
    height: 55px;
    margin: 0;
  }

  #footer .logomark {
    margin-bottom: 6px;
  }

  #footer .logomark.small {
    display: none;
    width: 43px;
  }

  #footer .social {
    margin: 0;
  }

  @media (max-width: 899px) {
    #footer .social a#email {
      display: none;
    }
  }

  #footer address {
    margin: 0 auto;
    width: 550px;
  }

  #footer address span.line {
    display: inline;
  }

  #footer address span.line::before {
    content: ' \00b7\ ';
    color: #4684B3;
  }

  #footer address span.line:first-child::before {
    content: '';
  }

  #footer address span.line:last-child::before {
    content: '';
  }

  #footer small {
    width: 450px;
    margin: 1em auto;
    font-size: 0.62em;
  }

  #footer div.inner {
    width: 580px;
    padding-right: 0;
    padding-left: 0;
  }

  body.green #header .shortcuts {
    background-color: transparent;
    border-bottom: none;
  }

  body.hide-donate #header {
    text-align: left;
  }

  body.green #footer .nav,
  body.green #footer .social {
    border-bottom: none;
    box-shadow: none;
  }

  body.hide-donate #header a.home {
    top: auto;
    left: auto;
    margin-top: 0;
  }

  body.green #header ~ #menu {
    color: #C5D3B0;

    a:hover {
      color: #C5D3B0;
    }
  }

  body.hide-donate #header div.inner {
    text-align: left;
  }
}

@media (min-width: $medium-minwidth) {
  #header {
    .logo {
      @include scale(1);

      height: 64px;
    }
  }

  .primary-nav {
    .nav > li:hover > a,
    .nav > li.current > a {
      background: none;

      &::after {
        bottom: 5px;
      }
    }
  }

  .secondary-nav {
    a {
      padding: 14px 5px 11px 5px;
      font-size: 14px;
    }
  }
}

@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/grid';
@import '../../css_variables/typography';
@import '../../css_variables/util';

html {
  min-width: 320px;
}

body {
  background-color: $legacy-green;
  min-width: 980px;
}

/* all supported non-IE8 browsers will pick this up */
@media (min-width: 0) {
  body {
    min-width: inherit;
  }
}

div.inner {
  width: $contentWidth;
  margin: 0 auto;
}

.content {
  background-color: white;
  padding-top: 37px;
  padding-bottom: 42px;
}

body.login .content {
  padding-top: 70px;
}

body.login .content hr {
  margin-bottom: 20px;
}

/******************************************************************************
 header
******************************************************************************/

.logo {
  -webkit-transform-origin: top left;
  -moz-transform-origin: top left;
  -o-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  margin-top: 19px;
  width: 209px;
  height: 64px;
  max-width: none; /* $IE8 */
  background-position: 0 -30px;
  overflow: hidden;
}

.search-logo {
  width: 60px;
  height: auto;
  margin-right: 2.318em;
  margin-right: 2.318rem;

  @media (max-width: $small-minwidth) {
    width: 45px;
    margin-right: 1em;
    margin-right: 1rem;
  }
}

#header {
  height: 139px;
  border-bottom: #B4975A 3px solid;
  color: white;
  background: $legacy-green;

  &.main-header {
    .logo {
      height: 35px;
      width: 227px;
      transform: none;

      @media (min-width: $xs-minwidth) {
        height: 39px;
        width: 253px;
      }

      @media (min-width: 900px) {
        height: 52px;
        width: 336px;
      }
    }
  }

  &.login {
    background: none;
    background-color: #F8F8F8;
    padding-top: 37px;
    padding-top: 2.318rem;
    margin-bottom: -45px;
    border-bottom: none;
    filter: none;
    text-align: center;

    .logo {
      width: 100%;
      height: 52px;
      background: transparent no-repeat;
      background-position: 0 -1000px; /* hack to hide sprite on ipod safari */
    }
  }

  &.simple {
    /* this is the header stripped of everything except the logo */
    height: 92px;
  }

  a.home {
    display: inline-block;
    z-index: 2; /* just needs to stack above #menu */
  }

  &.login div.inner {
    position: relative;
  }

  &.search {
    height: 146px;
    border-bottom: #B4975A 3px solid;
  }

  &.search div.inner div {
    float: left;

    h1 {
      margin-top: -4px;
      margin-bottom: -3px;

      @include block-text-26($largeBasePixels);
    }

    .need-help {
      @include block-text-16($largeBasePixels);

      color: $legacy-green-extra-light;

      a {
        color: inherit;
      }
    }

    .small-hidden {
      display: inline;
    }
  }

  a.menu {
    display: none;
  }

  a.donate {
    z-index: 3;
    display: inline-block;
    color: white;
    text-decoration: none;
    font-weight: 700;
    padding: 11px 20px;
    border-radius: 3px;
    font-size: 16px;
    line-height: 1.4;
    background: $legacy-blue; /* Old browsers */
    position: absolute;
    right: 271px;
    top: 23px;

    &::before {
      display: block;
      background-color: transparent;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      z-index: -1;
      content: ' ';
      border-radius: 5px;
      position: absolute;
    }
  }

  .shortcuts {
    position: absolute;
    right: 0;
    top: 23px;
    z-index: 2; /* bumping this above #menu */
  }

  form.search {
    width: 258px;

    @include rounded-corners(4px);

    background-color: #3D581D;
    padding: 4px;

    label {
      display: block;
      margin: 0;
      padding: 1px 4px;
      overflow: hidden;

      span {
        display: none;
      }
    }

    input {
      box-shadow: none;
      border: 0;
      background-color: #E8F0F5;
      color: #444;
      font-size: 16px;
      padding-top: 8px;
      padding-bottom: 11px;
      padding-left: 8px;

      @include rounded-corners(2px);

      height: auto;
    }

    div {
      background-color: #E8F0F5;
    }

    button {
      @include hide-text;

      top: 28%;
      right: 12px;
      width: 18px;
      height: 18px;
      display: inline-block;
      border: 0;
      padding: 0;
      cursor: pointer;
      position: absolute;
      box-shadow: none;
      font-size: 0;

      &::before {
        // Search icon
        content: "🔍";
        font-size: 15px;
        position: absolute;
        top: 1px;
        left: 1px;
        color: #518BB7;
        text-indent: 0;
        font-family: $font-family-icons;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        text-rendering: optimizeLegibility;
        text-shadow: none;
      }
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  //high pixel density (retina) screen logic
  #header .logo {
    background-size: 210px 138px !important;
  }
}

#header-search-input:-moz-placeholder {
  color: #696D6F;
}

#header-search-input::-webkit-input-placeholder {
  color: #696D6F;
}

/******************************************************************************
 menu
******************************************************************************/

#menu {
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  text-align: right;
  color: #FFF;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  z-index: 1;
  padding-top: 11px;
  padding-bottom: 1px;
  pointer-events: none;

  div.inner {
    padding-right: 396px;
    padding-top: 8px;
  }

  &.loggedin div.inner {
    padding-top: 0;
  }

  a {
    &:hover {
      color: inherit;
    }

    color: inherit;
    pointer-events: auto;
  }

  a.contact::before,
  a.logout::before {
    content: ' \00b7\ '; /* middot */
  }

  a.top {
    display: none;
  }
}

#header.search ~ #menu {
  top: 0;
}

#header.search ~ #menu .inner {
  padding-right: 0;
  padding-top: 8px;
}

#header.simple ~ #menu .inner {
  padding-right: 0;
}

/******************************************************************************
 nav
******************************************************************************/
.primary-nav {
  position: absolute;
  top: 87px;
  left: 0;
  right: 0;
  font-family: $font-family-serif;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  border-top: $legacy-green-dark 1px solid;
  box-shadow: inset rgba(255, 255, 255, 0.1) 0 1px 0 0;

  a {
    font-family: $font-family-serif;
    color: white;
    padding: 14px 28px 18px;
    display: inline-block;
    border-bottom: none;
  }

  li::before {
    content: ' \00b7\ ';
    font-weight: bold;
  }

  li:first-child::before {
    content: '';
  }

  .nav > li:hover > a,
  .nav > li.current > a {
    background: none;
    //CSS Triangle
    &::after {
      content: '';
      height: 0;
      width: 0;
      border-bottom: 5px solid #B4975A;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      position: absolute;
      bottom: 2px;
      left: 50%;
      margin-left: -2px;
    }
  }

  ul ul li:hover a {
    background-image: none;
  }

  ul ul li::before {
    content: '';
  }

  li ul {
    display: none;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 177px;
    background-color: #B4975A;
    text-align: left;
    overflow: hidden;
    font-size: 13px; /* FIXME */
    line-height: 16px; /* FIXME */
    font-weight: 500;
    border-radius: 0 0 5px 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    z-index: 1; /* needs a boost about primary-nav and anything else */
    font-family: $font-family-serif;
  }

  li:hover ul {
    display: block;
    z-index: 2;
  }

  li ul li {
    display: block;
  }

  li ul a {
    display: block;
    width: auto;
    padding: 10px 20px;

    &::after {
      display: none;
    }
  }

  .nav li ul a:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.primary-nav > .inner > ul > li > a {
  padding-left: 14px;
  padding-right: 14px;
}

/******************************************************************************
 secondary nav strip
******************************************************************************/

.secondary-nav {
  display: none;
  background-color: #E8F0F5;
  border-bottom: #D1E0EC 1px solid;
  color: #005595;
  text-align: center;

  @media (min-width: 760px) {
    display: block;
  }

  nav {
    display: flex;
    justify-content: center;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
  }
}

.secondary-nav li::before {
  content: '\00b7';
  font-weight: 700;
}

.secondary-nav li:first-child::before {
  content: '';
}

.secondary-nav a {
  font-family: $font-family-serif;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  color: inherit;
  padding: 14px 5px 11px 5px;
  display: inline-block;
  border-bottom: none;
  text-decoration: underline;

  &:hover {
    color: #001B2F;
  }
}

/******************************************************************************
 footer
******************************************************************************/

#footer {
  color: white;
  background-color: $legacy-green;
  padding-bottom: 36px;
  text-align: center;
  font-size: 14px;
  line-height: 1.618;
  border-top: $discovery-gold 3px solid;
  padding-top: 37px;

  a {
    color: inherit;
    font-weight: 500;
    text-decoration: none;
    border-bottom: none;

    &:hover {
      color: lighten($legacy-green, 40%);
    }
  }

  a.home {
    display: inline-block;
    padding-bottom: 2px;
    // The size of the logo
    width: 68px;
    height: 60px;
    overflow: hidden;
  }

  .nav {
    position: absolute;
    width: 418px;
    left: 0;
    text-align: right;
    height: 40px;
    top: 20px;
    font-size: 12px;
    margin-left: 0;

    a {
      font-weight: 500;

      &::before {
        content: '\00B7\ ';
        color: #C5D2B1;
      }
    }
  }

  .social {
    position: absolute;
    text-align: left;
    right: 0;
    top: 10px;
    width: 418px;
    font-family: $font-family-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    letter-spacing: 4px;
    font-size: 10px;

    a,
    span {
      display: inline-block;
      vertical-align: middle;
    }

    .twitter,
    .facebook,
    .linkedin,
    .instagram,
    .tiktok {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      color: white;
    }

    .email-link {
      margin-right: 3px;
    }

    span {
      color: #C5D2B1;
    }

    a:hover {
      opacity: 0.5;
    }

    #find {
      top: 2px;
    }
  }

  address a,
  small a {
    border-bottom: #C5D2B1 1px dotted;
    font-weight: 400;
  }

  .logomark {
    opacity: 0.3;
    filter: alpha(opacity=33);
    max-width: none; /* $IE8 */
    margin-bottom: 6px;
    background: url('logomark_white.png') center no-repeat;
    width: 100%;
    height: 100%;
    display: block;

    &.small {
      display: none;
      width: 43px;
    }
  }

  .dot {
    color: #4684B3;
  }

  small {
    display: block;
    color: #C5D2B1;
    font-size: 11px;
    line-height: 1.618;
  }

  address {
    margin-bottom: 0;

    span.line::before {
      content: ' \00b7\ ';
      color: #4684B3;
    }

    span.line:first-child::before {
      content: '';
    }

    a {
      font-weight: normal;
    }
  }
}

@media (min-width: 1132px) {
  // this footer is only currently used on the metro pages
  #footer .logomark {
    background: url('logomark.svg');
    background-size: 100%;
    background-position: 0 -93%;
  }
}

#footer .nav li:first-child a::before {
  content: '';
}

/**************************************************************
        .green body class - uses green header and footer
***************************************************************/

body.green #header {
  background: rgb(105, 146, 52); /* Old browsers */

  a.donate {
    background: #5C8727; /* Old browsers */
  }

  form.search {
    background: darken($legacy-green-dark, 2%);

    div {
      background-color: lighten($legacy-green-light, 42%);
    }

    input {
      background-color: lighten($legacy-green-light, 42%);
    }

    button {
      &::before {
        color: #5C8727;
      }
    }
  }
}

body.green #header ~ #menu {
  color: $legacy-green-extra-light;
}

body.green .primary-nav {
  border-top: $legacy-green 1px solid;
}

body.green #footer {
  background-color: $legacy-green;

  a:hover {
    color: $legacy-green-extra-light;
  }

  address a,
  small a {
    border-bottom: $legacy-green-light 1px dotted;
  }

  .nav a::before {
    color: $legacy-green-light;
  }

  .social,
  small {
    color: $legacy-green-extra-light;
  }
}

/**************************************************************
        end .green body class
***************************************************************/

/**************************************************************
        .hide-donate body class - hides main header donate button
***************************************************************/
body.hide-donate #header a.donate {
  display: none;
}

body.hide-donate #header ~ #menu .inner {
  padding-right: 280px;
}

/**************************************************************
        end .hide-donate body class
***************************************************************/

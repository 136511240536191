// vars and mixins
@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/grid';
@import '../../css_variables/util';

// foundation module files
@import 'font_min';
@import 'reset';
@import 'util';
@import 'style';
@import 'style_small';
@import 'style_medium';
@import 'style_large';
@import 'forms';
@import 'components';
@import 'components_small';
@import 'components_medium';
@import 'components_large';
@import 'layout';
@import 'layout_small';
@import 'layout_medium';
@import 'layout_large';
@import 'new_header';

//mega_menu layout file
@import '../../css_modules/foundation/mega_menu';

// cookie consent banner
@import '../../css_modules/foundation/privacy_consent_banner';

.mypage--banner {
  background: $legacy-blue;
  width: 100%;
  color: white;
  padding: 28px;
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  z-index: 20000;
  transform: translate(0, -100%);
  transition:
    transform 200ms cubic-bezier(0.58, 0.09, 0.67, 1.33),
    background 100ms linear;
  font-family: $font-family-sans;

  &.loading {
    background: $legacy-blue;
    transform: translate(0, 0);
  }

  &.error {
    background: $sbf-red !important;
    color: white;

    .button.confirm {
      color: $sbf-red;
    }
  }

  &.new_error {
    animation: wiggle-with-it 200ms ease-out;
  }

  .button {
    background-color: transparent;
    border-color: $sbf-white;
    border-radius: 3px;
    color: $sbf-white;
    font-family: $sourceSans;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 10px;
    text-decoration: none;
    transition: background 50ms linear, border-color 50ms linear, color 50ms linear, opacity 50ms linear;
    white-space: nowrap;
    z-index: 2;
    padding: 15px 16px;
    font-size: 18px;
    line-height: 1.15em;
    min-width: 40px;
    letter-spacing: 0;

    &.close {
      position: relative;
      top: unset;
      right: unset;
      opacity: unset;
    }

    &:hover,
    &:focus {
      color: $sbf-white;
      border-bottom-width: 2px;
      border-color: $sbf-white;
      background-color: transparent;
    }
  }

  .button.confirm {
    min-width: 88px;
    background: white;
    border-color: rgba(255, 255, 255, 0.5);
    color: $legacy-blue;
  }

  .button.confirm + .button.close {
    font-weight: 400;
  }

  .banner__controls {
    @media (max-width: 600px) {
      display: block;
      padding: 0.5em 0;
    }
  }

  &.long {
    .button {
      display: block;
      margin: 12px auto 0;
      padding: 8px 24px;
    }
  }

  a {
    text-decoration: none;
    color: white;
    border-bottom: 1px solid white;
    padding: 2px 0;

    &:hover {
      color: white;
      border-color: rgba(255, 255, 255, 0.7);
    }
  }

  &.warning {
    background-color: #C95000;
    padding: 28px 20px;
    text-align: center;

    p {
      @include block-text-16($largeBasePixels);

      font-family: $font-family-sans;
      color: #FFF;
      max-width: 80ch;
      margin: 0 auto 12px auto;
    }
  }
}

@import '../../css_variables/typography';

.mega-menu {
  font-family: $font-family-serif;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1;
  font-weight: 400;
  border: none;
  background-color: #F0F4EC;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.mega-menu .inner {
  text-align: left;
  width: 770px;
  margin: 0;
  display: inline-block;
  vertical-align: text-bottom;
  padding: 0;
}

.mega-menu .foundation.learn-more {
  color: #5C8727;
}

.mega-menu ul.nav {
  margin: 0;
  margin-top: 15px;
}

.mega-menu img {
  display: inline-block;
  vertical-align: top;
  width: 179px;
}

.mega-menu ul.nav > li {
  display: inline-block;
  width: 148px;
  text-align: left;
}

.mega-menu ul.nav > li:last-child {
  width: 60px;
}

.mega-menu ul.nav li {
  display: inline-block;
  background: #F0F4EC;
  border: none;
  vertical-align: text-top;
}

.mega-menu ul.nav li:first-child::before {
  content: '';
}

.mega-menu em,
.mega-menu em a {
  letter-spacing: 0.025em;
}

.mega-menu a {
  color: #005595;
  padding: 16px 16px 12px;
  display: block;
  border-bottom: none;
  text-decoration: none;
}

.mega-menu a:hover {
  color: #0C85CD;
}

.mega-menu ul ul li:hover a {
  background-image: none;
}

.mega-menu > .inner > ul > li > a {
  padding-left: 14px;
  padding-right: 14px;
  font-weight: 800;
}

.mega-menu ul ul li::before {
  content: '';
}

.mega-menu li ul {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-size: 0.885rem;
}

.mega-menu li ul li {
  border: none;
}

.mega-menu li ul a {
  display: block;
  width: auto;
  padding-top: 0;
  line-height: 1rem;
}

.mega-menu li ul a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 0) {
  .mega-menu {
    padding-top: 0;
    padding-bottom: 0;
  }

  .mega-menu .inner {
    text-align: center;
    width: 100%;
  }

  .mega-menu img {
    display: none;
  }

  .mega-menu .foundation.learn-more {
    display: none;
  }

  .mega-menu ul.nav {
    margin-top: 0;
  }

  .mega-menu ul.nav li {
    border-top: #C5D3B0 1px solid;
    display: block;
  }

  .mega-menu ul.nav > li {
    display: block;
    width: auto;
  }

  .mega-menu ul.nav > li:last-child {
    width: auto;
  }

  .mega-menu li ul {
    display: none;
    margin: -6px auto 0;
    padding: 0 28px 8px;
  }

  .mega-menu li ul a {
    padding: 8px 16px 8px;
  }
}

@media (min-width: 680px) {
  .mega-menu {
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: center;
  }

  .mega-menu .inner {
    width: 670px;
  }

  .mega-menu ul.nav li {
    border: none;
    vertical-align: text-top;
  }

  .mega-menu ul.nav > li {
    display: inline-block;
    width: 148px;
    text-align: left;
  }

  .mega-menu ul.nav > li:last-child {
    width: 60px;
  }

  .mega-menu li ul {
    display: inline-block;
    padding: 0;
    margin: 0;
  }

  .mega-menu li ul a {
    padding-top: 0;
    line-height: 1rem;
  }
}

@media (min-width: 830px) {
  .mega-menu {
    padding-top: 60px;
    padding-bottom: 42px;
  }

  .mega-menu .foundation.learn-more {
    display: block;
  }

  .mega-menu img {
    display: inline-block;
    vertical-align: top;
    width: 140px;
  }

  .mega-menu .inner {
    text-align: left;
    width: 670px;
    margin: 0;
    display: inline-block;
    vertical-align: text-bottom;
  }

  .mega-menu ul.nav {
    margin-top: 15px;
  }
}

@media (min-width: 980px) {
  .mega-menu img {
    width: 179px;
  }

  .mega-menu .inner {
    width: 770px;
  }

  .mega-menu ul.nav > li {
    width: 168px;
  }
}

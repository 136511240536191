@import '../../css_variables/color';

// Overrides on default styles
#termsfeed-pc1-notice-banner {
  .termsfeed-pc1-notice-banner-layout {
    box-sizing: border-box;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    border-radius: 3px 3px 0 0;

    @media (min-width: 900px) {
      max-width: 50%;
    }
  }

  .termsfeed-pc1-notice-banner-inner {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }

  .termsfeed-pc1-notice-banner-footer {
    padding: 0;
  }

  .termsfeed-pc1-notice-banner-content {
    margin: 0 0 12px 0;

    .termsfeed-pc1-notice-banner-content-weusecookies-text,
    .termsfeed-pc1-notice-banner-content-youcanchange-text {
      font-size: 1.125em;
      line-height: 1.3333333333;
      font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif !important;
      letter-spacing: normal;
      color: $sbf-white;
    }
  }

  .termsfeed-pc1-notice-banner-footer-savepreferences-button {
    margin: 0 6px 0 0;
  }

  .termsfeed-pc1-notice-banner-content-weusecookies-purposes-text {
    display: block;
    margin: 0 0 4px 0;
  }
}

#termsfeed-pc1-preferences-center {
  font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-weight: 600;

  .termsfeed-pc1-preferences-center-footer-rejectall-button {
    @media (min-width: 993px) {
      margin: 0 6px;
    }
  }

  .termsfeed-pc1-preferences-center-footer-saveselection-button {
    margin: 0 6px 0 0;
  }
}

#termsfeed-pc1-notice-banner,
#termsfeed-pc1-preferences-center {
  .termsfeed-pc1-notice-banner-footer-acceptall-button,
  .termsfeed-pc1-notice-banner-footer-rejectall-button,
  .termsfeed-pc1-preferences-center-footer-acceptall-button,
  .termsfeed-pc1-preferences-center-footer-rejectall-button,
  .termsfeed-pc1-preferences-center-footer-saveselection-button,
  .termsfeed-pc1-notice-banner-footer-savepreferences-button {
    background-color: $sbf-blue;
    border-color: $sbf-blue;
    border-radius: 3px;
    color: #FFF;
    font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background 50ms linear, border-color 50ms linear, color 50ms linear, opacity 50ms linear;
    white-space: nowrap;
    z-index: 2;
    letter-spacing: -0.005em;
    padding: 10px 16px 12px 16px;
    font-size: 16px;
    min-width: initial;
    line-height: 1.15em;

    &:hover,
    &:focus {
      color: $sbf-white;
      border-bottom-width: 2px;
      border-color: #003862;
      background-color: #003862;
    }
  }

  .termsfeed-pc1-notice-banner-footer-close-button,
  .termsfeed-pc1-preferences-center-footer-close-button {
    background-color: $sbf-green;
    border-color: $sbf-green;
    border-radius: 3px;
    color: #FFF;
    font-family: "Source Sans 3", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-weight: bold;
    border-width: 2px;
    border-style: solid;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    transition: background 50ms linear, border-color 50ms linear, color 50ms linear, opacity 50ms linear;
    white-space: nowrap;
    z-index: 2;
    letter-spacing: -0.005em;
    padding: 10px 16px 12px 16px;
    font-size: 16px;
    min-width: initial;
    line-height: 1.15em;

    &:hover,
    &:focus {
      color: $sbf-white;
      border-bottom-width: 2px;
      border-color: #49652D;
      background-color: #49652D;
    }
  }

  a {
    color: $sbf-white;

    &:hover,
    &:focus {
      color: #CFCFCF;
    }
  }
}

.termsfeed-pc1-ec-wrapper {
  display: none !important;

  &.termsfeed-pc1-visible {
    display: none !important;
  }
}

@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/util';

/*
* This is the large (desktop) version
*/
@media (min-width: 900px) {
  #header {
    height: 145px;
    border-bottom: #B4975A 3px solid;
    color: white;
    background: $legacy-green;

    div.inner {
      margin: 0 auto;
      padding-left: 0;
      padding-right: 0;
    }

    &.login {
      background: none;
      background-color: #F8F8F8;
      padding-top: 37px;
      padding-top: 2.318rem;
      margin-bottom: -45px;
      border-bottom: none;
      filter: none;
      text-align: center;
    }

    &.simple {
      /* this is the header stripped of everything except the logo */
      height: 92px;
    }

    a.home {
      display: inline-block;
      z-index: 2; /* just needs to stack above #menu */
    }

    .logo {
      margin-top: 0;
      margin-top: 14px;
      max-width: none; /* $IE8 */
    }

    &.search {
      height: 146px;
    }

    &.search div.inner div {
      h1 {
        @include block-text-26($largeBasePixels);
      }

      .need-help {
        @include block-text-16($largeBasePixels);
      }

      .small-hidden {
        display: inline;
      }

      a.home {
        top: inherit;
      }
    }

    a.donate {
      margin-right: 0;
      display: inline-block;
      color: white;
      text-decoration: none;
      font-weight: 700;
      box-shadow: none;
      position: absolute;
      right: 271px;
      top: 17px;

      &::before {
        display: block;
        background-color: transparent;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        z-index: -1;
        content: ' ';
        border-radius: 5px;
        position: absolute;
        box-shadow: none;
      }
    }

    .shortcuts {
      padding-right: 0;
      position: absolute;
      right: 0;
      top: 17px;
    }

    form.search {
      width: 258px;

      @include rounded-corners(4px);

      padding: 4px;

      label {
        display: block;
        margin: 0;
        padding: 1px 4px;

        span {
          display: none;
        }
      }

      input {
        box-shadow: none;
        border: 0;
        color: #444;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 8px;

        @include rounded-corners(2px);

        height: 35px;
      }
    }
  }

  #header-search-input:-moz-placeholder {
    color: #696D6F;
  }

  #header-search-input::-webkit-input-placeholder {
    color: #696D6F;
  }

  #menu {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    text-align: right;
    font-size: 12px;
    line-height: 1rem;

    div.inner {
      padding-right: 364px;
      padding-top: 3px;
    }

    &.loggedin div.inner {
      padding-top: 0;
    }

    .secondrow {
      display: block;
    }

    a {
      color: inherit;

      &:hover {
        color: #FFF;
      }
    }

    a.contact::before,
    a.logout::before {
      content: ' \00b7\ '; /* middot */
    }

    a.members::before {
      content: '';
    }

    a.top {
      display: none;
    }

    a.emails {
      display: none;
    }
  }

  #header.search ~ #menu .inner {
    padding-right: 0;
  }

  #header.simple ~ #menu .inner {
    padding-right: 0;
  }

  .primary-nav {
    top: 93px;
  }

  .primary-nav a {
    padding: 14px 28px 18px;
  }

  .primary-nav .nav > li {
    font-size: 18px;
    font-weight: 500;
  }

  .primary-nav > .inner > nav > ul > li > a {
    padding-left: 14px;
    padding-right: 14px;
  }

  .primary-nav .nav > li:hover > a,
  .primary-nav .nav > li.current > a {
    &::after {
      bottom: 6px;
    }
  }

  .primary-nav li ul {
    top: 50px;
  }

  #footer {
    color: white;
    background-color: $legacy-green;
    padding-bottom: 36px;
    text-align: center;
    font-size: 16px;
    line-height: 1.618;
    border-top: $discovery-gold 3px solid;
    padding-top: 37px;
  }

  #footer div.inner {
    width: 100%;
    max-width: 900px;
    padding-right: 0;
    margin: 0 auto;
  }

  #footer a {
    color: inherit;
    text-decoration: none;
    border-bottom: none;
  }

  #footer address {
    width: auto;
    font-size: 0.9em;
    margin-bottom: 0;
  }

  #footer small {
    width: auto;
    margin-top: 0;
    margin-bottom: 23px;
    display: block;
    line-height: 1.618;
  }

  #footer address a,
  #footer small a {
    border-bottom: #C5D2B1 1px dotted;
    font-weight: 400;
  }

  #footer a.home {
    display: inline-block;
    padding-bottom: 2px;
    margin-top: 0;
  }

  #footer .nav {
    position: absolute;
    width: 400px;
    left: 0;
    text-align: right;
    height: 40px;
    top: 20px;
    font-size: 0.92em;
    margin-left: 0;
    border-bottom: none;
    box-shadow: none;
  }

  #footer .nav a::before {
    content: ' \00B7\ ';
    color: #C5D2B1;
  }

  #footer .nav li:first-child a::before {
    content: '';
  }

  #footer address span.line:last-child::before {
    content: ' \00B7\ ';
    color: #C5D2B1;
  }

  #footer .social {
    position: absolute;
    text-align: left;
    right: 0;
    top: 14px;
    width: 400px;
    font-family: $font-family-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    letter-spacing: 4px;
    font-size: 10px;
    border-bottom: none;
    box-shadow: none;
    padding: 0;
    margin-top: 6px;
  }

  #footer .facebook {
    background-color: transparent;
  }

  #footer .logomark {
    margin-bottom: 6px;
  }

  #footer .logomark.small {
    display: none;
    width: 43px;
  }

  #footer .dot {
    color: #C5D2B1;
  }

  #footer address span.line::before {
    content: ' \00b7\ ';
    color: #C5D2B1;
  }

  #footer address span.line:first-child::before {
    content: '';
  }

  #footer address a {
    font-weight: normal;
  }

  #footer #find {
    padding-left: 0;
  }

  #footer #contact {
    padding-right: 0;
  }

  body.hide-donate #header ~ #menu div.inner {
    padding-right: 272px;
  }

  #footer .social a#email {
    text-transform: none;
    font-size: 14px;
    text-align: left;
    color: #FFF;
    letter-spacing: 0;
  }

  #footer .social a#email:hover {
    color: #C9DDB4;
    opacity: 1;
  }

  .social #email::after {
    content: ' \00B7 ';
    padding-left: 5px;
    color: #C5D2B1;
  }
}

@media (min-width: 1020px) {
  div.inner {
    width: 880px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  #content {
    div.inner {
      width: 940px;
    }
  }

  #header {
    div.inner {
      width: 1020px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  #menu {
    div.inner {
      padding-right: 299px;
    }
  }
}

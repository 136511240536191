@import '../../css_variables/breakpoints';
@import '../../css_variables/typography';

@media (min-width: $zero-minwidth) {
  html,
  body {
    font-size: 14px;
    line-height: 20px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dd,
  header,
  p,
  small,
  figure,
  address,
  blockquote,
  label,
  pre,
  table,
  fieldset,
  hr {
    margin-bottom: 20px; // this number MUST match the line-height set on the body
  }

  h1.heavy {
    text-align: center;
  }

  p.lead {
    @include block-text-16;
  }

  /* just the mark on small, no lines */
  hr {
    margin: 23px auto 42px;
  }
}

@media (max-width: $small-minwidth) {
  h1 {
    font-size: 36px;
    line-height: 1;
  }

  h2 {
    font-size: 28px;
    line-height: 1.1;
  }
}

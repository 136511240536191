@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/util';

/*******************************************************************************
 features and alerts
 ******************************************************************************/

.feature,
.alert {
  background-color: $secondary-yellow-background;
  border: #DDD 1px solid;
  border-color: rgba(0, 0, 0, 0.05);

  @include rounded-corners(5px);

  padding: 14px;
  padding: 0.885rem;
  color: $secondary-yellow;
  margin-bottom: 23px;
  margin-bottom: 1.432rem;
}

.alert {
  padding-right: 37px;
  padding-right: 2.318rem;
}

.close,
.close:hover {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  box-shadow: none;
  font-size: 1.432rem;
  font-weight: bold;
  position: absolute;
  top: 12px;
  top: 0.75rem;
  right: 14px;
  right: 0.885rem;
  opacity: 0.2;
  color: black;
}

.close:hover {
  opacity: 0.4;
}

.close:active {
  opacity: 0.6;
}

.feature.info,
.alert.info {
  @include info-light-on-dark;
}

.feature.warning,
.alert.warning {
  @include warning-light-on-dark;
}

.feature.success,
.alert.success {
  @include success-light-on-dark;
}

.feature.danger,
.alert.danger,
.feature.error,
.alert.error {
  @include danger-light-on-dark;

  font-weight: 700;
}

.feature.neutral,
.alert.neutral {
  @include neutral-light-on-dark;
}

.alert.popover {
  position: absolute;
  border-width: 0;
  z-index: 1;
}

.alert.popover::after {
  position: absolute;
  display: block;
  content: "";
  border-top-color: $secondary-yellow-background;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-style: solid;
  border-width: 10px;
  height: 0;
  width: 0;
  bottom: -20px;
  left: 44%;
}

.alert.popover.success::after {
  border-top-color: $color-success-background;
}

.alert.popover.danger::after,
.alert.popover.error::after {
  border-top-color: $color-danger-background;
}

.alert.popover.info::after {
  border-top-color: $color-info-background;
}

/*******************************************************************************
 buttons
 ******************************************************************************/
@mixin button {
  font-family: $font-family-sans;
  font-weight: 600;

  @include rounded-corners(3px);

  text-decoration: none;
  cursor: pointer;
  border: none;
  padding: 0.885em 1.25em;
  line-height: 1em;
  background: #777;
  color: white;
  display: inline-block;
  letter-spacing: normal;
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

@mixin button-hover {
  background-color: lighten(#777, 6%);
  color: white;
}

input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  @include button;
}

input[type="file"]::-webkit-file-upload-button {
  @include button;
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
button:hover,
a.button:hover {
  @include button-hover;
}

input[type="file"]:hover::-webkit-file-upload-button {
  @include button-hover;
}

input.fill[type="button"],
input.fill[type="submit"],
input.fill[type="reset"],
button.fill,
a.button.fill {
  width: 100%;
}

input.mini[type="button"],
input.mini[type="submit"],
input.mini[type="reset"],
button.mini,
a.button.mini {
  font-size: 12px;
  font-size: 0.75rem;
}

input.small[type="button"],
input.small[type="submit"],
input.small[type="reset"],
button.small,
a.button.small {
  font-size: 14px;
  font-size: 0.885rem;
}

input.medium[type="button"],
input.medium[type="submit"],
input.medium[type="reset"],
button.medium,
a.button.medium {
  font-size: 20px;
  font-size: 1.25rem;
}

input.large[type="button"],
input.large[type="submit"],
input.large[type="reset"],
button.large,
a.button.large {
  font-size: 23px;
  font-size: 1.432rem;
}

input.xl[type="button"],
input.xl[type="submit"],
input.xl[type="reset"],
button.xl,
a.button.xl {
  font-size: 26px;
  font-size: 1.618rem;
}

input.success[type="button"],
input.success[type=submit],
button.success,
.button.success {
  @include success-white-text;
}

input.success[type="button"]:hover,
input.success[type="submit"]:hover,
input.success[type="reset"]:hover,
button.success:hover,
a.button.success:hover,
.button-hover.success {
  background-color: desaturate(lighten($color-success, 5%), 5%);
}

input.primary[type="button"],
input.primary[type=submit],
button.primary,
.button.primary {
  @include primary-white-text;
}

input.primary[type="button"]:hover,
input.primary[type="submit"]:hover,
input.primary[type="reset"]:hover,
button.primary:hover,
a.button.primary:hover,
.button-hover.primary {
  background-color: desaturate(lighten($color-primary, 5%), 5%);
}

input.warning[type="button"],
input.warning[type=submit],
button.warning,
.button.warning {
  @include warning-white-text;
}

button.purple,
.button.purple {
  @include purple-white-text;
}

button.baby-blue,
.button.baby-blue {
  @include baby-blue-white-text;
}

button.blue,
.button.blue {
  @include blue-white-text;
}

input.warning[type="button"]:hover,
input.warning[type="submit"]:hover,
input.warning[type="reset"]:hover,
button.warning:hover,
a.button.warning:hover,
.button-hover.warning {
  background-color: desaturate(lighten($color-warning, 5%), 5%);
}

input.purple[type="button"]:hover,
input.purple[type="submit"]:hover,
input.purple[type="reset"]:hover,
a.button.purple:hover,
.button-hover.purple {
  background-color: desaturate(lighten($legacy-purple, 5%), 5%);
}

input.baby-blue[type="button"]:hover,
input.baby-blue[type="submit"]:hover,
input.baby-blue[type="reset"]:hover,
a.button.baby-blue:hover,
.button-hover.baby-blue {
  background-color: desaturate(lighten($legacy-baby-blue, 5%), 5%);
}

input.blue[type="button"]:hover,
input.blue[type="submit"]:hover,
input.blue[type="reset"]:hover,
a.button.blue:hover,
.button-hover.blue {
  background-color: desaturate(lighten($color-primary, 5%), 5%);
}

input.info[type="button"],
input.info[type=submit],
button.info,
.button.info {
  @include info-white-text;
}

input.info[type="button"]:hover,
input.info[type="submit"]:hover,
input.info[type="reset"]:hover,
button.info:hover,
a.button.info:hover,
.button-hover.info {
  background-color: desaturate(lighten($color-info, 5%), 5%);
}

input.danger[type="button"],
input.danger[type=submit],
button.danger,
.button.danger,
input.error[type="button"],
input.error[type=submit],
button.error,
.button.error {
  @include danger-white-text;
}

input.error[type="button"]:hover,
input.error[type="submit"]:hover,
input.error[type="reset"]:hover,
button.error:hover,
a.button.error:hover,
.button-hover.error,
input.danger[type="button"]:hover,
input.danger[type="submit"]:hover,
input.danger[type="reset"]:hover,
button.danger:hover,
a.button.danger:hover,
.button-hover.danger {
  background-color: desaturate(lighten($color-error, 5%), 5%);
}

input.inverse[type="button"],
input.inverse[type=submit],
button.inverse,
.button.inverse {
  @include inverse-white-text;
}

input.inverse[type="button"]:hover,
input.inverse[type="submit"]:hover,
input.inverse[type="reset"]:hover,
button.inverse:hover,
a.button.inverse:hover,
.button-hover.inverse {
  background-color: lighten($color-inverse, 6%);
}

input.facebook[type="button"],
input.facebook[type="submit"],
button.facebook,
.button.facebook {
  background-color: $color-facebook-background;
  color: white;
}

input.facebook[type="button"]:hover,
input.facebook[type="submit"]:hover,
button.facebook:hover,
.button.facebook:hover {
  background-color: lighten($color-facebook-background, 6%);
}

input.twitter[type="button"],
input.twitter[type="submit"],
button.twitter,
.button.twitter {
  @include info-white-text;
}

input.twitter[type="button"]:hover,
input.twitter[type="submit"]:hover,
button.twitter:hover,
.button.twitter:hover {
  background-color: desaturate(lighten($color-info, 5%), 5%);
}

.button.button--outline {
  background-color: white;
  background-image: none;
  color: #777;
  text-shadow: none;
  box-shadow: none;
  border: 1px solid #777;
  text-transform: none;
}

.button.button--outline:hover {
  color: white;
  background-color: #777;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0);
}

.button.button--outline.info {
  border-color: $legacy-blue;
  color: $legacy-blue;

  &:hover,
  &:active {
    color: white;
    background-color: $legacy-blue;
  }
}

.button--outline.success {
  color: $legacy-green;
  border-color: $legacy-green;

  &:hover,
  &:active {
    color: white;
    background-color: $legacy-green;
  }
}

.button.button--disabled,
.button.button--disabled:hover {
  color: #A3A3A3;
  background: #DADADA;
  cursor: default;
  border-color: #DADADA;
  box-shadow: none;
}

form.search {
  border-radius: 4px;
}

form.search label {
  display: block;
  padding: 4px;
}

form.search label span {
  display: none;
}

form.search input {
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 1px 0;
  border: 0;
  background-color: #FFF;
  background-color: rgba(255, 255, 255, 0.9);
  color: #444;
  font-size: 16px;

  @include rounded-corners(2px);

  height: auto;
}

form.search button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  top: 13px;
  right: 12px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  box-shadow: none;
  background: none;

  &::before {
    // Search icon
    content: "🔍";
    font-size: 15px;
    position: absolute;
    top: 11px;
    left: 1px;
    color: #518BB7;
    text-indent: 0;
    font-family: "SSSymbolicons";
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    text-shadow: none;
  }
}

.search-box input {
  width: 100%;
  padding-right: 35px;
}

.search-box button {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  top: 13px;
  right: 10px;
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  box-shadow: none;
  font-size: 0;
  background: none;

  &::before {
    // Search icon
    content: "🔍";
    font-size: 15px;
    position: absolute;
    top: 11px;
    left: 1px;
    color: #518BB7;
    text-indent: 0;
    font-family: "SSSymbolicons";
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    text-shadow: none;
  }
}

::-webkit-search-cancel-button {
  display: none;
}

/*******************************************************************************
 cards
 ******************************************************************************/

.entity-card {
  display: inline-block;
  width: 100%;

  * {
    font-family: $font-family-sans;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  .avatar {
    float: left;
    width: 60px;
    margin-right: 16px;
    box-shadow: none;
    text-align: center;
    z-index: 2;

    .photo-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      margin: 0 0 4px 0;

      img {
        max-width: 60px;
        max-height: 60px;
      }
    }

    ul.actions {
      max-width: 60px;
      font-size: 12px;
      line-height: 14px;

      li {
        font-size: 10px;
        font-size: 0.618rem;
        font-family: $font-family-sans;
      }

      a.button {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
      }
    }
  }

  div.info {
    display: block;
    width: 100%;
    padding-left: 76px;

    span.type {
      display: block;
      margin-bottom: 3px;
      font-size: 10px;
      font-size: 0.618rem;
      line-height: 1;
      color: #B8B8B8;
      text-transform: uppercase;
      letter-spacing: 0.129rem;
      font-weight: 600;
    }

    span.rank {
      display: inline;

      @include block-text-23($largeBasePixels);

      font-weight: 700;
      margin-bottom: 10px;
      margin-right: 5px;
      color: #CCC;
    }

    span.rank.medium-hidden {
      display: none;
    }

    @media (min-width: $zero-minwidth) {
      span.rank.medium-hidden {
        display: inline;
      }
    }

    @media (min-width: $medium-minwidth) {
      span.rank.medium-hidden {
        display: none;
      }
    }

    span.name {
      display: inline;

      @include block-text-23($largeBasePixels);

      font-weight: 700;
      margin-bottom: 10px;
    }

    span.subname {
      display: block;

      @include block-text-14($largeBasePixels);

      font-weight: normal;
    }

    ul.meta li {
      font-size: 14px;
      font-size: 0.885rem;
      line-height: 20px;
      line-height: 1.25rem;
      font-weight: 500;
      color: #737373;

      &.half {
        /* prototype for two-column card meta layouts,
                 * but will probably need adjusting
                 * wherever it is used
                 */
        width: 50%;
        float: left;
      }

      i {
        font-size: 16px;
        font-size: 1rem;
        float: left;
        width: 16px;
        margin-right: 4px;
        vertical-align: middle;
      }

      span {
        display: block;
        vertical-align: top;
        line-height: 16px;
        line-height: 1rem;
        margin-left: 22px;
        margin-bottom: 5px;
      }
    }
  }
}

/*******************************************************************************
form/page controls
*******************************************************************************/
a.cancel {
  text-decoration: none;
  border-bottom: none;
}

/******************************************************************************
hidden and visible classes
******************************************************************************/
.hidden {
  display: none;
}

.visible {
  display: block;
}

/*******************************************************************************
 page headers
 ******************************************************************************/

.page-header {
  margin-bottom: 37px;
  margin-bottom: 2.318rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  .headline {
    padding-top: 23px;
    border-bottom: $discovery-gold 3px solid;
    background-color: $legacy-green;
    color: white;

    a {
      color: #D3DEC4;
    }
  }

  .subheadline {
    a {
      color: #005595;

      &:hover {
        color: #167BC6;
      }
    }

    @include success-light-on-dark;

    text-shadow: none;
    padding-top: 23px;
    padding-top: 1.432rem;
    padding-bottom: 23px;
    padding-bottom: 1.432rem;

    .entity-card ul.meta li {
      color: #5C8727;
    }
  }

  &.form {
    margin-bottom: 0;
  }
}

.page-header .subheadline .entitytitle {
  margin-top: 8px;
}

.page-header .subheadline .eventmeta {
  font-weight: 600;
}

.page-header.green .subheadline .info .name {
  color: #333;
}

.page-header.green .subheadline .info .subname {
  color: #333;
}

.page-header.green .subheadline .info .type {
  color: #97B776;
}

.page-header .entity-card ul.meta li {
  color: #5C8727;
}

.page-header.green i {
  color: #97B776;
}

.page-header span {
  font-size: 14px;
}

.page-header h1 {
  line-height: 1;
  font-size: 23px;
  font-size: 1.4375rem;
  margin-bottom: 20px;
}

.page-header .eventname {
  font-size: 23px;
  color: #222;
  font-weight: 700;
}

.page-header span,
.page-header small {
  line-height: 1;
  margin-bottom: 3px;
}

.page-header.green small {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 10px;
}

.page-header hr {
  margin: 0 0 16px 0;
  margin-bottom: 1rem;
  height: 2px;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-top-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  background: none;
}

.avatar {
  box-shadow: rgba(0, 0, 0, 0.33) 0 1px 1px;
}

/*******************************************************************************
 tertiary navigation
 ******************************************************************************/
ul.nav.tertiary {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal;
  text-align: center;
  margin-bottom: 4px;
  margin-top: 4px;
}

ul.nav.tertiary li {
  margin-left: 5px;
  margin-right: 5px;
}

ul.nav.tertiary li::after {
  margin-left: 12px;
  font-weight: bold;
  font-style: normal;
  text-decoration: inherit;
  display: inline-block;
  content: "\00b7";
}

ul.nav.tertiary li:first-child {
  display: block;
}

ul.nav.tertiary li:first-child::after {
  font-weight: normal;
  content: "\25be"; /* down arrow - starts at smallest size pointing down */

  /* right arrow - override at breakpoint when content is all on one line */

  /* content: "\25b8"; */
}

ul.nav.tertiary li:last-child::after {
  content: none;
}

ul.nav.tertiary li a {
  border-bottom: none;
  text-decoration: none;
}

.to-top-link a.top {
  color: #005595;

  @include block-text-10;

  font-weight: 700;
  text-transform: uppercase;
  padding: 8px;
  background: url('top-arrow.png') no-repeat right;
  padding-right: 15px;
}

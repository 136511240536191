@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/util';
@import "~sbf-design-system/scss/variables";

@mixin text-input-style {
  background-color: white;
  border: 1px solid #CCC;

  @include rounded-corners(3px);

  padding: 10px;
  height: 45px;
  font-size: 16px;
  -webkit-appearance: none;
}

textarea,
input[type=text],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
    /*select,*/
select[multiple] {
  @include text-input-style();
}

input,
textarea {
  width: 210px;
}

input:hover,
textarea:hover,
button:hover {
  border-color: #A2C1D8;
}

input:focus,
textarea:focus,
button:focus,
input.info {
  border-color: #A2C1D8;
  box-shadow: rgba(162, 193, 216, 1) 0 0 4px 1px;
  outline-width: 0;
}

input.success,
select.success,
textarea.success {
  border-color: $legacy-green-light;
  box-shadow: $legacy-green-light 0 0 4px 1px;
}

input::-webkit-outer-adjust-hue-button,
input::-webkit-inner-adjust-hue-button {
  -webkit-appearance: none;
  margin: 0;
}

input.error,
select.error,
textarea.error,
input.danger,
select.danger,
textarea.danger {
  border-color: $secondary-red-light;
  box-shadow: $secondary-red-light 0 0 4px 1px;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

select {
  display: inline-block;
  height: 45px;
}

select[multiple] {
  padding: 0;
  background: white;
  height: auto;
}

select[multiple] option {
  margin: 6px;
}

/* give form elements some room */
input + input,
input + button,
input + select,
select + select,
select + input {
  margin-left: 1em;
}

label > input[type=checkbox] + span,
label > input[type=radio] + span {
  display: inline-block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 400;
  width: 90%;
  margin-left: 4px;
  vertical-align: top;
}

input + small,
select + small,
button + small {
  margin-left: 8px;
}

input[type="text"]:disabled,
input[type="number"]:disabled,
select:disabled {
  background: #F0F0F0;
  border-color: #CCC;
}

input[type="text"]:disabled:hover,
input[type="number"]:disabled:hover,
select:disabled:hover {
  border-color: #CCC;
}

.inline-radio-group {
  font-size: 14px;
  font-size: 0.875rem;
}

.inline-radio-group label {
  display: inline-block;
  font-weight: normal;
  margin-right: 23px;
  margin-bottom: 0;
}

.inline-radio-group input[type=radio] {
  margin-right: 4px;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
input[type="file"],
input[type="radio"],
input[type="checkbox"] {
  width: auto;
}

form .helper {
  font-family: $font-family-sans;
  font-size: 14px;
  font-size: 0.885rem;
  color: #909090;
}

.input-prefix,
.input-suffix {
  @include text-input-style;

  display: inline-block;
  background: $gray-extra-light;
  color: $gray-medium;
  font-weight: bold;
  vertical-align: top;
}

.input-prefix {
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-prefix + input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  vertical-align: top;
}

.has-input-suffix,
.input-prefix + input.has-input-suffix {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  z-index: 1;
}

.input-suffix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}

.email-checker {
  position: absolute;
  left: 0;
  right: 0;
  font-size: 14px;
  z-index: 1002;
  background-color: $sbf-palette-white;
  margin-top: 4px;
  display: flex;
  white-space: nowrap;
  line-height: 1.45;
}

.email-checker__label {
  display: flex;
  overflow: hidden;
}

.email-checker__result {
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  direction: rtl;
  margin-left: 0.2em;
  padding: 0;
  font-weight: 600;
  text-decoration: underline;

  &:focus-visible {
    outline-offset: -2px;
  }
}

.email-checker__close {
  cursor: pointer;
  margin-left: 3px;
  height: 14px;
  display: inline-block;
  margin-top: 2px;
  padding: 0;
}

body.form div.content,
body.login {
  background-color: #F8F8F8;
}

body.form div.white-form {
  background-color: white;

  @include rounded-corners(4px);

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  padding: 0 0 37px 0;
}

body.form div.with-page-header {
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  -moz-border-radius-bottomleft: 4px;
  -moz-border-radius-bottomright: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

@mixin form-section-style {
  border-top: 1px solid #DBDBDB;
  padding-top: 20px;
  padding-top: 1.25rem;
  margin-top: 16px;
  margin-top: 1rem;
}

body.form h2 {
  font-family: $font-family-serif;
  font-weight: 600;
  text-transform: uppercase;

  @include block-text-16($largeBasePixels);

  letter-spacing: 2px;
  color: #B4975A;
  margin-bottom: 0;
  margin-top: 37px;
}

body.form h3 {
  font-size: 14px;
  margin-bottom: 16px;
}

body.form fieldset {
  @include form-section-style;
}

body.form p,
body.form ul,
body.form ol,
body.form dl {
  font-family: $font-family-sans;
}

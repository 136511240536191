@import '../../css_variables/breakpoints';
@import '../../css_variables/color';
@import '../../css_variables/typography';
@import '../../css_variables/util';
@import 'util';

/*
* This is the thinnest (mobile) version
*/
@media (min-width: $zero-minwidth) {
  div.inner {
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
  }

  #header {
    height: 113px;
    border-bottom: none;

    &.simple {
      height: 70px;
      text-align: center;
    }

    div.inner {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    &.login div.inner {
      position: absolute;
    }

    .logo {
      @include scale(0.75);

      margin-top: 0;
      height: 50px;
    }

    &.login .logo {
      margin-top: 19px;
    }

    a.home {
      top: 16px;
      left: 0;
      margin-left: -7px;
    }

    .logo.small {
      display: inline-block;
      margin-top: 0;
      width: 154px;
    }

    &.search {
      height: 73px;
    }

    &.search div.inner div {
      h1 {
        @include block-text-20($smallBasePixels);

        margin-top: 0;
      }

      .need-help {
        font-size: 11px;
      }

      .small-hidden {
        display: none;
      }
    }

    .shortcuts {
      background-color: #E8F0F5;
      border-top: #B4975A 3px solid;
      border-bottom: #D1E0EC 1px solid;
      margin-bottom: 0;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 46px;
    }

    a.donate {
      position: absolute;
      top: 13px;
      right: 17px;
    }

    a.menu {
      font-size: 9.6px;
      line-height: 1;
      color: #4684B3;
      font-weight: 700;
      display: inline-block;
      text-decoration: none;
      text-transform: uppercase;
      border-right: #D1E0EC 1px solid;
      position: absolute;
      left: 0;
      width: 78px;
      bottom: 0;
      top: 1px;
      text-align: center;
      padding-top: 16px;
      padding-right: 16px;

      &::after {
        //arrow down
        content: '⬇';
        font-family: $font-family-icons;
        color: #4684B3;
        position: absolute;
        top: 15px;
        right: 12px;
        font-size: 14px;
        text-indent: 0;
      }
    }

    form.search {
      margin-left: 79px;
      left: 0;
      bottom: 0;
      top: 0;
      background: transparent;
      width: auto;
      border-radius: 0;
      box-shadow: none;
      padding: 0;

      label {
        margin-top: 2px;
        padding: 0;
      }

      input {
        border-radius: 0;
        padding-right: 40px;
        padding-left: 8px;
        height: 40px;
        vertical-align: bottom;
        background: transparent;
        box-shadow: none;
        color: $gray-dark;
      }

      button {
        right: 15px;
        top: 28%;

        &::before {
          top: 11px;
        }
      }
    }
  }

  #menu {
    background-color: white;
    top: auto;
    position: relative;
    text-align: left;
    font-size: 16px;
    line-height: 1.35em;
    color: #4684B3;
    padding-top: 8px;
    padding-bottom: 8px;

    div.inner {
      padding-top: 0;
      padding-right: 20px;
    }

    a.contact::before {
      content: '';
    }

    a.emails::before {
      content: ' \00b7\ '; /* middot */
    }

    a.signin {
      display: inline-block;
    }

    a.contact {
      display: none;
    }

    a.members {
      display: none;
    }

    a:hover {
      color: #4684B3;
    }

    a.top {
      display: inline-block;
      color: #4684B3;
      position: absolute;
      top: 0;
      right: 20px;
      font-size: 12px;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 8px;
      margin-top: -8px;
      padding-right: 15px;

      &::after {
        //arrow up
        content: '⬆';
        font-family: $font-family-icons;
        color: #4684B3;
        position: absolute;
        top: 11px;
        right: -6px;
        font-size: 14px;
        text-indent: 0;
      }
    }
  }

  #header.search ~ #menu .inner {
    padding-right: 20px;
  }

  .primary-nav {
    background-color: #E8F0F5;
    position: relative;
    top: inherit;
    text-align: left;
    border: none;
    box-shadow: none;
  }

  .primary-nav .inner {
    padding: 0;
  }

  .primary-nav ul {
    margin: 0;
  }

  .primary-nav li {
    display: block;
    background: #E8F0F5;
    border-top: #D1E0EC 1px solid;
    font-size: 16px;
    font-weight: 500;
  }

  .primary-nav li::before {
    content: '';
  }

  .primary-nav a {
    padding: 12px 16px 12px;
    color: #005595;
    text-decoration: none;
    display: block;
  }

  .primary-nav .nav > li:hover > a::after,
  .primary-nav .nav > li.current > a::after {
    display: none;
  }

  .primary-nav a:hover,
  #menu a:hover {
    color: #0C85CD;
  }

  .primary-nav li ul {
    position: relative;
    left: inherit;
    top: inherit;
    width: auto;
    height: auto;
    padding: 0 28px 8px;
    margin-top: 6px;
    font-size: 0.885rem;
    background-color: inherit;
  }

  .primary-nav li:hover ul {
    display: none;
  }

  .primary-nav li.current ul {
    display: block;
  }

  .primary-nav li ul li {
    border: none;
  }

  .primary-nav li ul a {
    padding: 8px 16px 8px;
  }

  .primary-nav li ul a:hover {
    background-color: inherit;
  }

  .secondary-nav {
    display: none;
  }

  #footer {
    font-size: 16px;
    padding-top: 17px;
    padding-bottom: 23px;
  }

  #footer a.home {
    padding-bottom: 0;
    width: 43px;
    height: 38px;
    margin: 0 24px 14px;
  }

  #footer .nav {
    width: auto;
    height: auto;
    top: auto;

    /* $footer_chunk */
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.05);

    /* end */
    padding-bottom: 17px;
    position: relative;
    text-align: center;
    margin: 0 -5px;
    font-family: $font-family-serif;
    font-size: 0.8em;
  }

  #footer .social {
    width: auto;
    position: relative;
    text-align: center;

    /* $footer_chunk */
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.05);

    /* end */
    padding-top: 12px;
    padding-bottom: 6px;
    margin: 0 -5px;
    margin-bottom: 21px;
    font-size: 10px;
    letter-spacing: 2px;
    top: auto;
  }

  #footer small {
    width: 300px;
    margin: 0 auto;
    font-size: 10px;
  }

  #footer address {
    margin-bottom: 1rem;
    font-size: 12px; /* manual adjustment */
    font-size: 0.9em;
  }

  #footer address span.line {
    display: block;
  }

  #footer address span.line::before {
    content: '';
  }

  @media (max-width: 760px) {
    #footer .social a#email {
      display: none;
    }
  }

  @media (max-width: 390px) {
    #footer .nav {
      font-size: 9.5px;
    }
  }

  @media (max-width: 360px) {
    #footer .nav {
      font-size: 9px;
    }
  }

  @media (max-width: 320px) {
    #footer .nav {
      font-size: 8px;
    }
  }

  /**************************************************************
        .hide-donate body class - hides main header donate button
    ***************************************************************/
  body.hide-donate #header {
    height: 112px;
    text-align: center;
  }

  body.hide-donate #header a.home {
    top: auto;
    left: auto;
    margin-top: 15px;
  }

  #header.simple a.home {
    top: auto;
    left: auto;
    margin-top: 15px;
  }

  body.hide-donate #header ~ #menu .inner {
    padding-right: 20px;
  }

  /**************************************************************
        end .hide-donate body class
    ***************************************************************/
}
